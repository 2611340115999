@font-face {
  font-family: "Poppins Regular";
  font-style: normal;
  font-weight: 400;
  src: local("Poppins"),
  url("../assets/fonts/Poppins-Regular.woff2") format("woff2"),
  url("../assets/fonts/Poppins-Regular.woff") format("woff");
}

@font-face {
  font-family: "Poppins Medium";
  font-style: normal;
  font-weight: 500;
  src: local("Poppins"),
  url("../assets/fonts/Poppins-Medium.woff2") format("woff2"),
  url("../assets/fonts/Poppins-Medium.woff") format("woff");
}

@font-face {
  font-family: "Poppins SemiBold";
  font-style: normal;
  font-weight: 600;
  src: local("Poppins"),
  url("../assets/fonts/Poppins-SemiBold.woff2") format("woff2"),
  url("../assets/fonts/Poppins-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Poppins Bold";
  font-style: normal;
  font-weight: 700;
  src: local("Poppins"),
  url("../assets/fonts/Poppins-Bold.woff2") format("woff2"),
  url("../assets/fonts/Poppins-Bold.woff") format("woff");
}

